<template>
  <v-container fluid>
    <SecondaryNavigation />
    <v-row
      ><v-col cols="11"><Span class="text-h5 font-weight-bold mx-4">Trainer Courses</Span> </v-col>
      <v-col cols="1"> <CourseUploadDialog /></v-col>
    </v-row>
    <v-row>
      <CourseCard/>
    </v-row>
    <div :class="true?activeClass:nonActive">
             Admin comments
    </div>
   <AdminComments :showAddButton="true" type="TOP_COURSE"/>
  </v-container>
</template>

<script>
import SecondaryNavigation from "@/components/SecondaryNavigation/SecondaryNavigation.vue";
import AdminComments from "@/components/AdminComments/AdminComments.vue";
import CourseUploadDialog from "@/components/CourseUpload/CourseUploadDialog";
import CourseCard from "@/components/CourseCard/CourseCard";
import { mapState } from "vuex";
export default {
  name: "AdminConsole",
  components: {
    SecondaryNavigation,
   AdminComments,
    CourseUploadDialog,
    CourseCard
  },
  data() {
    return {
      activeClass:"text-h6 font-weight-bold mx-3",
      nonActive:"text-h6 primary--text font-weight-bold",
    }
  },
  computed: {
    ...mapState("trainerProfile", {
      loading: "loading",
      profile: "profile"
    })
  },
};
</script>

<style></style>
