<template>
<v-container>
  <div v-if="coursesList.length>0">
    <v-list  v-for="(courseEntity,index) in coursesList" :key="index" color="#ECF0F3">
  <v-row>
    <v-col cols="12">
      <v-card class="mt-4 mb-4" flat>
        <v-row>
          <v-col>
            <div class="text-h6 font-weight-bold ml-6">
              {{courseEntity.courseName}}
            </div>
          </v-col>
          <v-col cols="1">
            <EditCourse :id="courseEntity.id"/>
          </v-col>
        </v-row>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <div class="text-h7 font-weight-bold primary--text">Course Duration</div>
              </v-col>
              <v-col cols="6">
                <div class="text-h7 font-weight-bold text--primary">{{courseEntity.courseDuration}}</div>
              </v-col>
              <v-col cols="6">
                <div class="text-h7 font-weight-bold primary--text">Course Complexity</div>
              </v-col>
              <v-col cols="6">
                <div class="text-h7 font-weight-bold text--primary">{{courseEntity.courseComplexity}}</div>
              </v-col>

              <!-- <v-col cols="2">
                <div class="text-h7 text--primary">Course Link</div>
              </v-col> -->
             
              <v-col cols="6">
                <div class="text-h7 font-weight-bold primary--text">Target Audience</div>
              </v-col>
              <v-col cols="6">
                <div class="text-h7 font-weight-bold text--primary">{{courseEntity.targetAudienceDesignation}}</div>
              </v-col>
              <v-col cols="6">
                <div class="text-h7 font-weight-bold primary--text">Target Department</div>
              </v-col>
              <v-col cols="6">
                <div class="text-h7 font-weight-bold text--primary">{{courseEntity.targetDepartment}}</div>
              </v-col>
              <v-col cols="6">
                <div class="text-h7 font-weight-bold primary--text">Target Industry</div>
              </v-col>
              <v-col cols="6">
                <div class="text-h7 font-weight-bold text--primary">{{courseEntity.tragetIndustry}}</div>
              </v-col>

              <v-col cols="6">
                <div class="text-h7 font-weight-bold primary--text">Fee: Full day Session</div>
              </v-col>
              <v-col cols="6">
                <div class="text-h7 font-weight-bold text--primary">Min: ₹ {{courseEntity.minimumFees}} - Std:₹ {{courseEntity.standardFees}}</div>
              </v-col>

              <v-col cols="6">
                <div class="text-h7 font-weight-bold primary--text">
                  Certificate associated
                </div>
              </v-col>
              <v-col cols="6">
                <div class="text-h7 font-weight-bold text--primary">{{courseEntity.associatedCertification?Object.values(courseEntity.associatedCertification)[0]:null}}</div>
              </v-col>
              <v-col cols="6">
                <div class="text-h7 font-weight-bold primary--text">
                  Award associated
                </div>
              </v-col>
              <v-col cols="6">
                <div class="text-h7 font-weight-bold text--primary">{{courseEntity.associatedAwards?Object.values(courseEntity.associatedAwards)[0]:null}}</div>
              </v-col>
              <v-col cols="6">
                <div class="text-h7 font-weight-bold primary--text">
                  Patent associated
                </div>
              </v-col>
              <v-col cols="6">
                <div class="text-h7 font-weight-bold text--primary">{{courseEntity.associatedPatent?Object.values(courseEntity.associatedPatent)[0]:null}}</div>
              </v-col>
              <v-col cols="6">
                <div class="text-h7 font-weight-bold primary--text">
                  Publication associated
                </div>
              </v-col>
              <v-col cols="6">
                <div class="text-h7 font-weight-bold text--primary">{{courseEntity.associatedPublications?Object.values(courseEntity.associatedPublications)[0]:null}}</div>
              </v-col>
              <v-col cols="6">
                <div class="text-h7 font-weight-bold primary--text">
                  Total trainings sessions delivered for this course
                </div>
              </v-col>
              <v-col cols="6">
                <div class="text-h7 font-weight-bold text--primary">{{courseEntity.trainingsDeliveredForCourse}}</div>
              </v-col>

              <v-col cols="6">
                <div class="text-h7 font-weight-bold primary--text">
                  Session delivered last year
                </div>
              </v-col>
              <v-col cols="6">
                <div class="text-h7 font-weight-bold text--primary">{{courseEntity.sessionDeliveredForCourse}}</div>
              </v-col>

              <v-col cols="6">
                <div class="text-h7 font-weight-bold primary--text">Key Clients</div>
              </v-col>
              <v-col cols="6">
                <div class="text-h7 font-weight-bold text--primary" v-for="(client,index) in courseEntity.keyCleintsCourse" :key="index">{{client}}</div>
              </v-col>

              <v-col cols="6">
                <div class="text-h7 font-weight-bold primary--text">
                  Consulting Experience on this skill
                </div>
              </v-col>
              <v-col cols="6">
                <div class="text-h7 font-weight-bold text--primary">{{courseEntity.courseSKillsconsultingExperince?"Yes":"No"}}</div>
              </v-col>
              <v-col cols="6">
                <div class="text-h7 font-weight-bold primary--text">
                  Have you applied this skill during your corporate tenure?
                </div>
              </v-col>
              <v-col cols="6">
                <div class="text-h7 font-weight-bold text--primary">{{courseEntity.appliedSkillInCorporate?"Yes":"No"}}</div>
              </v-col>

              <v-col cols="6">
                <div class="text-h7 font-weight-bold primary--text">
                  Key Skills Covered in this Course
                </div>
              </v-col>
              <v-col cols="6">
                <div class="text-h7 font-weight-bold text--primary" v-for="(skill,index) in courseEntity.keySkillOfCourse" :key="index">{{skill}}</div>
              </v-col>
              <v-col cols="6">
                <div class="text-h7 font-weight-bold primary--text">
                  Course Outline
                </div>
              </v-col>
               <v-col cols="6" class="mx-n12 font-weight-bold primary--text">
                <DownloadFile fileLabel="" fileType="COURSE_UPLOAD" :fileUrl="courseEntity.courseOutlineUrl" :index="index"/>
                <!-- <div class="text-h7"><a href="#">Course-outline.pdf</a></div> -->
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
    </v-list>
  </div>
  <!-- <v-card v-else>
    <h1>No Course Assocaited With Trainer</h1>
  </v-card> -->
  </v-container>
</template>

<script>
import EditCourse from "../CourseUpload/CourseUploadDialog.vue";
import DownloadFile from "../DownloadFile.vue"
import { mapState } from "vuex";
export default {
  name: "CourseCard",
   data() {
    return {
     }
  },
  computed: {
    ...mapState("trainerProfile", {
      loading: "loading",
      profile: "profile",
      coursesList: "coursesList"
    })
  },
  components: {
    EditCourse,
    DownloadFile
  },
  created() {
    this.$store.dispatch("trainerProfile/getAllCourses",this.profile.trainerId);
    }

};
</script>

<style></style>
