<template>
  <v-row justify="center" no-gutters>
    <v-dialog
      v-model="dialog"
      max-width="1400px"
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on }">
        <v-icon color="primary" v-if="id" dark v-on="on" @click="getCourse">
          border_color
        </v-icon>
        <v-icon color="primary" v-on="on" large v-else>
          add
        </v-icon>
        <!-- <v-btn color="primary" dark v-on="on">Upload a new Course</v-btn> -->
      </template>
      <v-card>
        <v-card-title>
          <v-row justify="space-around" no-gutters>
            <v-col cols="10">
              <span class="text-h5 font-weight-bold mx-3" v-if="!id"
              >Create new course entry</span
            >
            <span class="text-h5 font-weight-bold mx-3" v-else
              >Edit course</span
            >
            </v-col>
            <v-col cols="1">
              <v-icon color="red" @click="closeForm" dark large dense>
              cancel
            </v-icon>
            </v-col>
            <v-col>
              <v-icon
              color="green"
              @click="createCourse"
              v-if="!id"
              dark
              large
              dense
            >
              done
            </v-icon>
            <v-icon color="blue" v-else @click="updateCourse" dark large dense>
              done
            </v-icon>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="courseUploadForm">
              <v-row>
                <v-col cols="12" sm="6" md="8">
                  <div class="text-h6 font-weight-bold mx-2">
                  Course name <span class="red--text">*</span>
                </div>
                  <v-text-field
                    outlined
                    label=""
                    placeholder="Type course name"
                    v-model="topCourse.courseName"
                    :rules="rules.isRequired"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <div class="text-h6 font-weight-bold mx-2">
                  Course duration <span class="red--text">*</span>
                </div>
                  <v-text-field
                  outlined
                    v-model="topCourse.courseDuration"
                    placeholder="Type duration of course..."
                    label=""
                    required
                  ></v-text-field
                  >
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <div class="text-h6 font-weight-bold mx-2">
                  Course complexity <span class="red--text">*</span>
                </div>
                  <v-select
                  outlined
                    v-model="topCourse.courseComplexity"
                    :items="courseComplexity"
                    placeholder="Select course"
                    label=""
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <div class="text-h6 font-weight-bold mx-2">
                  Target audience <span class="red--text">*</span>
                </div>
                  <v-select
                  outlined
                  placeholder="Select target audince"
                    :items="targetAudience"
                    label=""
                    v-model="topCourse.targetAudienceDesignation"
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <div class="text-h6 font-weight-bold mx-2">
                  Target Department <span class="red--text">*</span>
                </div>
                  <v-combobox
                  outlined
                    :items="departments"
                    placeholder="Type target department"
                    v-model="topCourse.targetDepartment"
                    label=""
                  ></v-combobox>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <div class="text-h6 font-weight-bold mx-2">
                  Target industry <span class="red--text">*</span>
                </div>
                  <v-combobox
                  outlined
                    v-model="topCourse.tragetIndustry"
                    :items="industry"
                    label=""
                    placeholder="Type target inustry...."
                  ></v-combobox>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                   <div class="text-h6 font-weight-bold mx-2">
                  Minimum Fees INR <span class="red--text"></span>
                </div>
                  <v-text-field
                    outlined
                    label=""
                    placeholder="INR"
                    type="number"
                    v-model="topCourse.minimumFees"
                  ></v-text-field>
                </v-col>
                
                <v-col cols="12" sm="6" md="4">
                  <div class="text-h6 font-weight-bold mx-2">
                  Standard Fee INR <span class="red--text"></span>
                </div>
                  <v-text-field
                    outlined
                    label=""
                    type="number"
                    v-model="topCourse.standardFees"
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  md="8"
                  v-if="certificationList.length > 0"
                >
                <div class="text-h6 font-weight-bold mx-2">
                  Certificates Associated with this course <span class="red--text">*</span>
                </div>
                  <v-select
                    :items="certificationList"
                    v-model="topCourse.associatedCertification"
                    label=""
                    outlined
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="8" v-if="awardList.length > 0">
                  <div class="text-h6 font-weight-bold mx-2">
                  Awards associated with this course <span class="red--text">*</span>
                </div>
                  <v-select
                  outlined
                    :items="awardList"
                    v-model="topCourse.associatedAwards"
                    label=""
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="8"
                  v-if="publicationList.length > 0"
                >
                <div class="text-h6 font-weight-bold mx-2">
                  Publications associated with this course <span class="red--text">*</span>
                </div>
                  <v-select
                  outlined
                    :items="publicationList"
                    v-model="topCourse.associatedPublications"
                    label=""
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="8" v-if="patentList.length > 0">
                    <div class="text-h6 font-weight-bold mx-2">
                  Patent associated with this course <span class="red--text">*</span>
                </div>
                  <v-select
                  outlined
                    :items="patentList"
                    v-model="topCourse.associatedPatent"
                    label=""
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <div class="text-h6 font-weight-bold mx-2">
                  Total trainings delivered for this course <span class="red--text">*</span>
                </div>
                  <v-select
                  outlined
                    :items="[
                      '<20',
                      '250+',
                      '750+',
                      '1000+',
                      '20+',
                      '50+',
                      '70+',
                      '100+',
                      '200+'
                    ]"
                    v-model="topCourse.trainingsDeliveredForCourse"
                    label=""
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <div class="text-h6 font-weight-bold mx-2">
                  Sessions delivered in last 12 months <span class="red--text">*</span>
                </div>
                  <v-select
                  outlined
                    v-model="topCourse.sessionDeliveredForCourse"
                    :items="['10+', '20+', '50+', '70+', '100+', '200+']"
                    label=""
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <div class="text-h6 font-weight-bold mx-2">
                  Key clients you delivered training for <span class="red--text">*</span>
                </div>
                  <v-combobox
                  outlined
                    v-model="topCourse.keyCleintsCourse"
                    :items="clientList"
                    label=""
                    multiple
                  ></v-combobox>
                </v-col>

                <v-col cols="12" sm="6">
                  <div class="text-h6 font-weight-bold mx-2">
                  Key skills covered in this course <span class="red--text">*</span>
                </div>
                  <v-combobox
                    v-model="topCourse.keySkillOfCourse"
                    outlined
                    label=""
                    multiple
                  ></v-combobox>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-checkbox class="text-h6 font-weight-bold mx-2"
                    v-model="topCourse.courseSKillsconsultingExperince"
                    label="Do you have consulting Experience on these skills?"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-checkbox
                    class="text-h6 font-weight-bold mx-2"
                    v-model="topCourse.appliedSkillInCorporate"
                    label="Have you applied these skills during corporate tenure?"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12">
                  <UploadDoc type="COURSE_UPLOAD" />
                  <!-- <v-file-input
                    v-model="files"
                    placeholder="Upload Course outline"
                    label="File input"
                    multiple
                    required
                    prepend-icon="mdi-paperclip"
                  >
                    <template v-slot:selection="{ text }">
                      <v-chip small label color="primary">
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input> -->
                </v-col>
                <v-col>
                  <DowloadFile
                    fileLabel="Course Outline"
                    :fileUrl="topCourse.courseOutlineUrl"
                    fileType="COURSE_UPLOAD"
                  />
                </v-col>
              </v-row>
            <small>*indicates required field</small>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState } from "vuex";
import {Departments ,Industry ,CourseComplexity ,TargetAuinceCourseOutline ,CleintList} from '../../data/FormData'
import UploadDoc from "../uploadDoc.vue";
import DowloadFile from "../DownloadFile.vue";
export default {
  components: {
    UploadDoc,
    DowloadFile
  },
  data: () => ({
    departments:Departments,
    industry:Industry,
    courseComplexity:CourseComplexity,
    targetAudience:TargetAuinceCourseOutline,
    clientList:CleintList,
    awardList: [],
    certificationList: [],
    patentList: [],
    publicationList: [],
    dialog: false,
    files: [],
    rules: {
      isRequired: [(v) => !!v || "Required"]
    }
  }),
  props:["id"],
  async created() {
    let extraMiles = await this.$store.dispatch(
      "trainerProfile/getExtraMiles",
      this.profile.trainerId
    );
    console.log("extra miles", extraMiles);
    (this.awardList = extraMiles.awardList),
      (this.certificationList = extraMiles.certificationList),
      (this.patentList = extraMiles.patentList),
      (this.publicationList = extraMiles.publicaitonList);
  },
  computed: {
    ...mapState("trainerProfile", {
      topCourse: "topCourse",
      loading: "loading",
      profile: "profile",
      extraMilesList: "extraMilesList"
    })
  },
  methods: {
    closeForm() {
      this.dialog = false;
      this.$refs.courseUploadForm.reset();
    },
    cb() {
      this.dialog = false;
      this.$refs.courseUploadForm.reset();
    },
    createCourse() {
      if (this.$refs.courseUploadForm.validate()) {
        //     console.log("top  course ---", this.topCourse);
        // console.log("extra miles ", this.extraMilesList[0]);
        this.topCourse.trainerId = this.profile.trainerId;
        if (this.extraMilesList[0].awardList.length > 0) {
          let awardList = this.extraMilesList[0].awardList.filter(
            (award) => award.awardName == this.topCourse.associatedAwards
          );
          // console.log('awardList',awardList);
          this.topCourse.associatedAwards = {
            [awardList[0].id]: awardList[0].awardName
          };
        }

        if (this.extraMilesList[0].patentList.length > 0) {
          let patentList = this.extraMilesList[0].patentList.filter(
            (patent) => patent.patentName == this.topCourse.associatedPatent
          );
          // console.log('patent list ', patentList);
          this.topCourse.associatedPatent = {
            [patentList[0].id]: patentList[0].patentName
          };
        }
        if (this.extraMilesList[0].publicaitonList.length > 0) {
          let publicaitonList = this.extraMilesList[0].publicaitonList.filter(
            (publication) =>
              publication.publicationTitle ==
              this.topCourse.associatedPublications
          );
          this.topCourse.associatedPublications = {
            [publicaitonList[0].id]: publicaitonList[0].publicationTitle
          };
        }

        if (this.extraMilesList[0].certificationList.length > 0) {
          let certificationList = this.extraMilesList[0].certificationList.filter(
            (certification) =>
              certification.certificationName ==
              this.topCourse.associatedCertification
          );
          //console.log('certificationList ', certificationList);
          this.topCourse.associatedCertification = {
            [certificationList[0].id]: certificationList[0].certificationName
          };
        }
        //console.log('publicaitonList', publicaitonList);
        console.log("updated top course ---- ", this.topCourse);
        this.$store.dispatch("trainerProfile/createCourse", {
          course: this.topCourse,
          cb: this.cb
        });
      }
    },
    getCourse(){
      console.log('get this course');
      this.$store.dispatch("trainerProfile/getACourse", this.id);
      
    },
    updateCourse(){
      if (this.$refs.courseUploadForm.validate()) {
        if (this.extraMilesList[0].awardList.length > 0) {
          let awardList = this.extraMilesList[0].awardList.filter(
            (award) => award.awardName == this.topCourse.associatedAwards
          );
          this.topCourse.associatedAwards = {
            [awardList[0].id]: awardList[0].awardName
          };
        }
        
        if (this.extraMilesList[0].patentList.length > 0) {
          let patentList = this.extraMilesList[0].patentList.filter(
            (patent) => patent.patentName == this.topCourse.associatedPatent
          );
          this.topCourse.associatedPatent = {
            [patentList[0].id]: patentList[0].patentName
          };
        }

        if (this.extraMilesList[0].publicaitonList.length > 0) {
          let publicaitonList = this.extraMilesList[0].publicaitonList.filter(
            (publication) =>
              publication.publicationTitle ==
              this.topCourse.associatedPublications
          );
          this.topCourse.associatedPublications = {
            [publicaitonList[0].id]: publicaitonList[0].publicationTitle
          };
        }

        if(this.extraMilesList[0].certificationList.length>0){
            let certificationList = this.extraMilesList[0].certificationList.filter(
          (certification) =>
            certification.certificationName ==
            this.topCourse.associatedCertification
        );
        this.topCourse.associatedCertification = {
          [certificationList[0].id]: certificationList[0].certificationName
        };

        }

        
        console.log(
          "updated edit course----------------------------------",
          this.topCourse
        );
        this.$store.dispatch("trainerProfile/updateACourse", {
          course: this.topCourse,
          cb: this.cb
        });
      }
    }
  }
};
</script>
